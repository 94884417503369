import React, {useCallback, useState} from 'react';
import {Grid, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Leaderboard from '../Leaderboard';
import styles from '../../App.module.css';
import QuizChallenge from "./QuizChallenge";
import {convertKeyToDateString} from "../../functions/ConvertKeyToDateString"
import {ethers} from "ethers";

const QuizRealm = ({realm, onSelectRealm, selectedRealm, etherQuizWorldContract, account, functions}) => {
    const handleClick = () => {
        onSelectRealm(realm);
    };

    const closeRealm = (event) => {
        event.stopPropagation();
        onSelectRealm(null)
    };

    const [scoreSubmitted, setScoreSubmitted] = useState(false);
    const handleScoreSubmitted = useCallback(() => {
        setScoreSubmitted(true);
        setTimeout(() => setScoreSubmitted(false), 0);
    }, []);

    // state variable to keep track of whether leaderboard is open or closed
    const [challengeOpen, setChallengeOpen] = useState(true);
    const [leaderboardOpen, setLeaderboardOpen] = useState(false);
    const [pastLeaderboardsOpen, setPastLeaderboardsOpen] = useState(false);
    const [allTimeLeaderboardsOpen, setAllTimeLeaderboardsOpen] = useState(false);

    const [pastKeys, setPastKeys] = useState([]);

    // const now = new Date();
    // const month = now.getUTCMonth() + 1;
    // const year = now.getUTCFullYear();
    // const currentMonthKey = year * 100 + month;

    return (
        <div onClick={handleClick} className={styles.realmContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4 " className={styles.realmName}>{realm}</Typography>
                        </Grid>
                        {selectedRealm === realm && (
                            <Grid item>
                                <IconButton onClick={closeRealm}>
                                    <CloseIcon className={styles.closeIcon}/>
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {selectedRealm === realm && (
                <Grid container className={styles.realmContainer} alignItems="center" justifyContent="space-between"
                      spacing={2}>
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        {!challengeOpen && (
                            <Grid item xs={12} sm={4}>
                                <button
                                    id="challengeButton"
                                    className={styles.button}
                                    onClick={() => {
                                        setChallengeOpen(true);
                                        setLeaderboardOpen(false);
                                        setPastLeaderboardsOpen(false);
                                        setAllTimeLeaderboardsOpen(false);
                                    }}
                                >Challenge
                                </button>
                            </Grid>)}
                        {!leaderboardOpen && (
                            <Grid item xs={12} sm={4}>
                                <button
                                    id="leaderboardButton"
                                    className={styles.button}
                                    onClick={() => {
                                        setLeaderboardOpen(true);
                                        setChallengeOpen(false);
                                        setPastLeaderboardsOpen(false);
                                        setAllTimeLeaderboardsOpen(false);
                                    }}
                                >Current Leaderboard
                                </button>
                            </Grid>)}
                        {!pastLeaderboardsOpen && (
                            <Grid item xs={12} sm={4}>
                                <button
                                    id="pastLeaderboardsButton"
                                    className={styles.button}
                                    onClick={async () => {
                                        setPastKeys(await etherQuizWorldContract.methods.getPastLeaderboardKeys(ethers.encodeBytes32String(realm)).call())
                                        setPastLeaderboardsOpen(true);
                                        setChallengeOpen(false);
                                        setLeaderboardOpen(false);
                                        setAllTimeLeaderboardsOpen(false);
                                    }}
                                >Past Leaderboards
                                </button>
                            </Grid>)}
                        {!allTimeLeaderboardsOpen && (
                            <Grid item xs={12} sm={4}>
                                <button
                                    id="allTimeLeaderboardsButton"
                                    className={styles.button}
                                    onClick={() => {
                                        setAllTimeLeaderboardsOpen(true);
                                        setChallengeOpen(false);
                                        setLeaderboardOpen(false);
                                        setPastLeaderboardsOpen(false);
                                    }}
                                >Champions Leaderboard
                                </button>
                            </Grid>)}
                    </Grid>
                    {challengeOpen && (<Grid item xs={12} sm={12}>
                        <QuizChallenge
                            realm={realm}
                            etherQuizWorldContract={etherQuizWorldContract}
                            account={account}
                            functions={functions}
                            onScoreSubmitted={handleScoreSubmitted}
                        />
                    </Grid>)}
                    {leaderboardOpen && (
                        <Grid item xs={12} sm={12}>
                            <Leaderboard title={`Current ${realm} Leaderboard`}
                                         getLeaderboard={etherQuizWorldContract.methods.getLeaderboard(ethers.encodeBytes32String(realm)).call()}
                                         realm={realm} scoreSubmitted={scoreSubmitted} displayDate={false}/>
                        </Grid>)}
                    {pastLeaderboardsOpen && (
                        <Grid item xs={12} sm={12}>
                            {pastKeys.map((key, index) => {
                                return (<Leaderboard key={index}
                                    title={`${convertKeyToDateString(key)} ${realm} Leaderboard`}
                                    getLeaderboard={etherQuizWorldContract.methods
                                        .getPastLeaderboard(ethers.encodeBytes32String(realm), key)
                                        .call()}
                                    realm={realm}
                                    scoreSubmitted={scoreSubmitted}
                                    displayDate={false}/>);
                            })}
                        </Grid>)}
                    {allTimeLeaderboardsOpen && (
                        <Grid item xs={12} sm={12}>
                            <Leaderboard title={`All Time Champions ${realm} Leaderboard`}
                                         getLeaderboard={etherQuizWorldContract.methods.getAllTimeChampionsLeaderboard(ethers.encodeBytes32String(realm)).call()}
                                         realm={realm} scoreSubmitted={scoreSubmitted} displayDate={true}/>
                        </Grid>)}
                </Grid>
            )}
        </div>
    );
};

export default QuizRealm;
