import React, {useState} from 'react';

import ConnectWalletButton from '../components/ConnectWalletButton';
import styles from '../App.module.css';
import {useCheckOwnerAdmin} from "../hooks/UseCheckOwnerAdmin";
import {Grid, TextField, Typography} from "@mui/material";
import Web3 from "web3";
import {ethers} from "ethers";

function QuizWorldAdmin({etherQuizWorldContract, account, setAccount}) {

    const [isQuizWorldOwner, setIsQuizWorldOwner] = useState(false);

    const [isQuizWorldAdmin, setIsQuizWorldAdmin] = useState(false);

    useCheckOwnerAdmin(etherQuizWorldContract, account,
        setIsQuizWorldOwner, setIsQuizWorldAdmin);

    const [entryFee, setEntryFee] = useState('');
    const [challengeDuration, setChallengeDuration] = useState('');
    const [challengeQuestionsNumber, setChallengeQuestionsNumber] = useState('');
    const [submissionGracePeriod, setSubmissionGracePeriod] = useState('');
    const [serverPublicKey, setServerPublicKey] = useState('');
    const [quizRealmToAdd, setQuizRealmToAdd] = useState('');
    const [quizRealmToRemove, setQuizRealmToRemove] = useState('');
    const [quizAdminToAdd, setQuizAdminToAdd] = useState('');
    const [quizAdminToRemove, setQuizAdminToRemove] = useState('');

    return (

        <div className={styles.container}>
            <h1 className={styles.title}>Quiz World</h1>
            <div className={styles.connectContainer}>
                <ConnectWalletButton account={account} setAccount={setAccount}/>
            </div>
            {(isQuizWorldOwner || isQuizWorldAdmin) && (
                <Grid container className={styles.realmContainer} alignItems="center" justifyContent="center"
                      spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h2 " className={styles.realmName}>EtherQuizWorld.sol</Typography>
                    </Grid>
                    {isQuizWorldOwner && (
                        <Grid item xs={12} sm={12}>
                            <button className={styles.button} onClick={() =>
                                etherQuizWorldContract.methods.withdraw().send({from: account})}>
                                Withdraw
                            </button>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                        <button className={styles.button} onClick={() =>
                            etherQuizWorldContract.methods.snapshotAndClearAllLeaderboards().send({from: account})}>
                            Snapshot Leaderboards
                        </button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField} value={entryFee}
                                   onChange={(e) => setEntryFee(e.target.value)} fullWidth label="setEntryFee"/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <button
                            className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.setEntryFee(Web3.utils.toWei(entryFee, 'ether')).send({from: account})}
                        >
                        Set Entry Fee
                        </button>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField}  value={challengeDuration}
                        onChange={(e) => setChallengeDuration(e.target.value)} fullWidth label="setChallengeDuration"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.setChallengeDuration(challengeDuration).send({from: account})}
                        >
                        Set Challenge Duration (seconds)
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField} value={challengeQuestionsNumber}
                        onChange={(e) => setChallengeQuestionsNumber(e.target.value)} fullWidth label="setChallengeQuestions-Number"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() =>  etherQuizWorldContract.methods.setChallengeQuestions(challengeQuestionsNumber).send({from: account})}
                        >
                        Set Challenge Questions-Number (4 to 50)
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField}  value={submissionGracePeriod}
                        onChange={(e) => setSubmissionGracePeriod(e.target.value)} fullWidth label="setSubmissionGracePeriod"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.setSubmissionGracePeriod(submissionGracePeriod).send({from: account})}
                        >
                        Set Submission Grace Period (seconds)
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField}  value={serverPublicKey}
                        onChange={(e) => setServerPublicKey(e.target.value)} fullWidth label="setServerPublicKey"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.setServerPublicKey(serverPublicKey).send({from: account})}
                        >
                        Set Server Public Key
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField} value={quizRealmToAdd}
                        onChange={(e) => setQuizRealmToAdd(ethers.encodeBytes32String(e.target.value))} fullWidth label="addQuizRealm"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.addQuizRealm(quizRealmToAdd+' Realm').send({from: account})}
                        >
                        Add Quiz Realm
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField} value={quizRealmToRemove}
                        onChange={(e) => setQuizRealmToRemove(ethers.encodeBytes32String(e.target.value))} fullWidth label="removeQuizRealm"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.removeQuizRealm(quizRealmToRemove+' Realm').send({from: account})}
                        >
                        Remove Quiz Realm
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField}  value={quizAdminToAdd}
                        onChange={(e) => setQuizAdminToAdd(e.target.value)} fullWidth label="setAdmin"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.setAdmin(quizAdminToAdd).send({from: account})}
                        >
                        Set Admin
                        </button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <TextField className={styles.adminTextField}  value={quizAdminToRemove}
                        onChange={(e) => setQuizAdminToRemove(e.target.value)} fullWidth label="removeAdmin"/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        <button
                        className={styles.button}
                        onClick={() => etherQuizWorldContract.methods.removeAdmin(quizAdminToRemove).send({from: account})}
                        >
                        Remove Admin
                        </button>
                        </Grid>
                        </Grid>
                        )}
                    </div>
                    );
                    }

                    export default QuizWorldAdmin;
