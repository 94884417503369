import { useEffect, useCallback } from 'react';

export const useQuizCountdown = (challengeStarted, timeRemaining, setTimeRemaining, dispatch) => {
    const updateTimer = useCallback(() => {
        if (challengeStarted) {
            const timer = setInterval(() => {
                if (timeRemaining > 0) {
                    setTimeRemaining((prevTimeRemaining) => prevTimeRemaining - 1)
                }
            }, 1000);

            if (timeRemaining === 0) {
                clearInterval(timer);
                dispatch({ type: "setCurrentQuestion", payload: 10});
            }

            return () => clearInterval(timer);
        }
    }, [challengeStarted, timeRemaining, setTimeRemaining, dispatch]);

    useEffect(() => {
        const cleanup = updateTimer();
        return () => {
            if (cleanup) {
                cleanup();
            }
        };
    }, [updateTimer]);
};
