import {useCallback} from "react";
import Web3 from "web3";
import {getQuizQuestions} from "../functions/GetQuizQuestions";
import {ethers} from "ethers";

export const useGetQuizQuestions = (
                                        functions,
                                        etherQuizWorldContract,
                                        realm,
                                        account,
                                        startTime,
                                        setTimeRemaining,
                                        dispatch
                                    ) => {
    return useCallback(async () => {
        if (!Web3 || !account || !etherQuizWorldContract) return;
        dispatch({type: "setLoading", payload: true});
        try {
           const challengeQuestionsValue = await etherQuizWorldContract.methods.getChallengeQuestions().call();
           const entryFee = Web3.utils.fromWei(await etherQuizWorldContract.methods.getEntryFee(account).call(), 'ether');
            await etherQuizWorldContract.methods
                .startChallenge(ethers.encodeBytes32String(realm))
                .send({from: account,
                    value: Web3.utils.toWei(entryFee.toString(), 'ether'),
                    maxPriorityFeePerGas: '0x0',
                    maxFeePerGas: '0x5F5E100',
                    gas: '0x21F857' })
                .on('transactionHash', (hash) => {
                })
                .on('receipt', (receipt) => {
                    getQuizQuestions(functions, realm, challengeQuestionsValue)
                        .then(async (questions) => {
                        dispatch({type: "setQuestions", payload: await questions});
                        dispatch({type: 'setAnswers', payload: []});
                        dispatch({type: "setCurrentQuestion", payload: 0});
                        setTimeRemaining(startTime);
                        dispatch({type: 'setChallengeStarted', payload: true});
                    }).then(()=>{
                        dispatch({type: "setLoading", payload: false});
                    })
                })
                .on('error', (error) => {
                    console.error('Error fetching quiz questions and starting quiz challenge:', error);
                });
        } catch (err) {
            console.error('Error fetching quiz questions and starting quiz challenge:', err);
        }
    }, [account, etherQuizWorldContract, dispatch, realm, functions, setTimeRemaining, startTime]);
};
