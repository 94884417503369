export const connectWallet = async (setAccount = null) => {
    const ethereum = window.ethereum;
    if (!ethereum) {
        throw new Error('No Ethereum provider found.');
    }

    try {
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts.length > 0) {
            const connectedAccount = accounts[0];
            if (setAccount) {
                setAccount(connectedAccount);
            }
            return connectedAccount;
        } else {
            throw new Error('No accounts found.');
        }
    } catch (error) {
        throw new Error(`Failed to connect to wallet: ${error.message}`);
    }
};
