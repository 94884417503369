import {useReducer, useState} from 'react';
import styles from '../../App.module.css';
import PropTypes from 'prop-types';
import {useQuizCountdown} from "../../hooks/UseQuizCountdown";
import {useGetQuizQuestions} from "../../hooks/UseGetQuizQuestions";
import {useSubmitQuizAnswer} from "../../hooks/UseSubmitQuizAnswer";
import {useSubmitChallengeScore} from "../../hooks/UseSubmitChallengeScore";
import {Grid, CircularProgress} from "@mui/material";
import {useFetchChallengeDuration} from "../../hooks/UserFetchChallengeDuration";

const initialState = ({
    answers: [],
    questions: [],
    currentQuestion: 0,
    challengeStarted: false,
    time: 0,
    displayScore: 0,
    loading: false
});

function reducer(state, action) {
    switch (action.type) {
        case "setAnswers":
            return {...state, answers: action.payload};
        case "setQuestions":
            return {...state, questions: action.payload};
        case "setCurrentQuestion":
            return {...state, currentQuestion: action.payload};
        case "setChallengeStarted":
            return {...state, challengeStarted: action.payload};
        case "setTime":
            return {...state, time: action.payload};
        case "setDisplayScore":
            return {...state, displayScore: action.payload};
        case "setLoading":
            return {...state, loading: action.payload};
        default:
            return state;
    }
}

function QuizOption({optionKey, onClick, optionValue}) {
    return (
        <Grid item xs={12} sm={6} key={optionValue} className={styles.gameContainer}>
            <button
                className={styles.gameButton}
                onClick={() => onClick(optionKey)}
            >
                {optionValue}
            </button>
        </Grid>
    );
}
function QuizChallenge({realm, account, etherQuizWorldContract, functions, onScoreSubmitted}) {
    const [startTime, setStartTime] = useState(0);
    useFetchChallengeDuration(etherQuizWorldContract, account, setStartTime);

    const [state, dispatch] = useReducer(reducer, initialState);
    const {answers, questions, currentQuestion, challengeStarted, time, displayScore, loading} = state;
    const [timeRemaining, setTimeRemaining] = useState(60);

    useQuizCountdown(challengeStarted, timeRemaining, setTimeRemaining, dispatch);

    const startChallenge = useGetQuizQuestions(functions, etherQuizWorldContract, realm, account, startTime,
        setTimeRemaining, dispatch);
    const submitAnswer = useSubmitQuizAnswer(answers, currentQuestion, questions, dispatch);
    const submitChallengeScore = useSubmitChallengeScore(functions, realm, account, etherQuizWorldContract, onScoreSubmitted,
        answers, startTime, timeRemaining, setTimeRemaining, dispatch);

    return (
        <div className={styles.container}>
            <Grid container spacing={1} className={styles.titleContainer}>
                <h1 className={styles.title}>{realm} Challenge</h1>
            </Grid>
            {!challengeStarted && !loading && (
                <div className={styles.instructions}>
                    <h2>Instructions:</h2>
                    <ol>
                        <li>Click the "Start Challenge" button to begin the quiz.</li>
                        <li>You will be given a series of questions related to the {realm}. Answer each question as
                            quickly and accurately as possible.
                        </li>
                        <li>For each question, select the correct answer from the available options.
                            You must answer each question and cannot return to a previous question.</li>
                        <li>You have {startTime} seconds to complete the quiz. The timer will start as soon as the quiz
                            begins.
                        </li>
                        <li>Your final score will be based on the number of correct answers and the time taken to
                            complete the quiz.
                        </li>
                        <li>If your score is the same as another player then you will rank higher with a faster time.
                        </li>
                        <li>Once you have answered all the questions, click the "Submit Score" button to finish the quiz
                            and submit your score.
                        </li>
                    </ol>
                    <h3>WARNING: If you click away from the quiz to any other screen on EtherGameHub the quiz will disconnect and you will be unable to continue that game.
                        You will then have to wait for the timer to expire before playing that realm quiz again.
                        If this happens you would be able to start a different realm quiz</h3>
                </div>)}
            {(!challengeStarted && !loading) && (
                <button className={styles.button} onClick={startChallenge}>
                    Start Challenge
                </button>)}
            {!loading && !challengeStarted && currentQuestion === 0 && (
                <div className={styles.quizText}>
                    <p>Score: {displayScore}</p>
                    <p>Time: {time} seconds</p>
                </div>
            )}
            {loading && (
                <CircularProgress/>
            )}
            {!loading && challengeStarted && currentQuestion < questions.length && (
                <Grid container spacing={2} className={`${styles.quizText}`}>
                    <Grid item xs={12} className={styles.gameContainer}>
                        <p>Time remaining: {timeRemaining} seconds</p>
                    </Grid>
                    <Grid item xs={12} className={styles.gameContainer}>
                        <p>Question {currentQuestion + 1}: {questions[currentQuestion]?.question}</p>
                    </Grid>
                    {questions[currentQuestion]?.shuffledOptions.map((option, index) => (
                        <Grid item xs={12} sm={6} key={index} className={styles.gameContainer}>
                            <QuizOption
                                optionKey={option.key}
                                onClick={() => submitAnswer(option.key)}
                                optionValue={option.value}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            {challengeStarted && currentQuestion > questions.length - 1 && !loading && (
                <Grid container spacing={1} className={styles.buttonContainer}>
                    <button className={styles.button} onClick={submitChallengeScore}>
                        Submit Score
                    </button>
                </Grid>
            )}
        </div>
    );
}

QuizChallenge.propTypes = {
    account: PropTypes.string.isRequired,
    etherQuizWorldContract: PropTypes.object.isRequired,
    functions: PropTypes.object.isRequired,
    onScoreSubmitted: PropTypes.func.isRequired,
};

QuizOption.propTypes = {
    optionKey: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    optionValue: PropTypes.string.isRequired,
};

export default QuizChallenge;
