import { useEffect, useCallback } from 'react';
import {ethers} from "ethers";

/**
 * useFetchQuizRealms - A custom React Hook to fetch quiz realms from a contract,
 * and update the quiz realms state.
 *
 * @param {Object} etherQuizWorldContract - The contract to fetch quiz realms from.
 * @param {Function} setQuizRealms - Function to update the quiz realms state.
 */
export const useFetchQuizRealms = (etherQuizWorldContract, setQuizRealms) => {
    const fetchQuizRealms = useCallback(async () => {
        if (etherQuizWorldContract) {
            try {
                const realms = await etherQuizWorldContract.methods.getQuizRealms().call();
                const stringQuizRealms = realms.map(value => ethers.decodeBytes32String(value));
                setQuizRealms(stringQuizRealms);
            } catch (error) {
                console.error('Error fetching quiz realms:', error);
                // Here, you may want to handle the error more gracefully,
                // such as updating the UI with an error message.
            }
        }
    }, [etherQuizWorldContract, setQuizRealms]);

    useEffect(() => {
        fetchQuizRealms();
    }, [fetchQuizRealms]);
};
