import {useCallback} from 'react';
import {ethers} from "ethers";

export function useMintAllTimeChampionNft(account, etherQuizWorldContract) {
    return useCallback(async (entry) => {

        try {
            const realm = ethers.encodeBytes32String(entry.realm)
            await etherQuizWorldContract.methods.mintAllTimeChampionNFT(realm, entry.pos)
                .send({
                    from: account,
                    maxPriorityFeePerGas: '0x0',
                    maxFeePerGas: '0x5F5E100',
                    gas: '0x88A19C'
                });
        } catch (err) {
            console.error(err);
        }

    }, [account, etherQuizWorldContract]);
}
