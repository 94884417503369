import React, {useState} from 'react';

import QuizRealm from '../components/quizRealm/QuizRealm';
import NftRealm from "../components/nftRealm/NftRealm";
import ConnectWalletButton from '../components/ConnectWalletButton';
import styles from '../App.module.css';
import {Stars} from "../functions/StarUtils";
import {useAppHeight} from "../functions/UseAppHeight";
import {useFetchQuizRealms} from "../hooks/UseFetchQuizRealms";

function QuizWorld({etherQuizWorldContract, account, setAccount, functions}) {


    const [quizRealms, setQuizRealms] = useState([]);
    const [selectedRealm, setSelectedRealm] = useState(null);

    useFetchQuizRealms(etherQuizWorldContract, setQuizRealms, account);

    const onSelectRealm = (realm) => {
        setSelectedRealm(realm);
    };

    const starCount = 500;
    return (

        <div className={styles.container}>
            <h1 className={styles.title}>Quiz World</h1>
            {Stars(starCount, styles, useAppHeight())}
            <div className={styles.connectContainer}>
                <ConnectWalletButton account={account} setAccount={setAccount}/>
            </div>
            { quizRealms.map((realm, index) => (
                <QuizRealm
                    key={index}
                    realm={realm}
                    onSelectRealm={onSelectRealm}
                    selectedRealm={selectedRealm}
                    etherQuizWorldContract={etherQuizWorldContract}
                    account={account}
                    functions={functions}
                />
            ))}
            <NftRealm
                key={quizRealms.length}
                realm={"NFT Minting Realm"}
                quizRealms={quizRealms}
                onSelectRealm={onSelectRealm}
                selectedRealm={selectedRealm}
                etherQuizWorldContract={etherQuizWorldContract}
                account={account}
            />
        </div>
    );
}

export default QuizWorld;
