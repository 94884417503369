import React, {useState} from "react";
import {NavLink as RouterNavLink} from "react-router-dom";
import {AiOutlineMenu} from "react-icons/ai";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import styles from "./App.module.css";

const Navbar = () => {
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    const toggleBurgerMenu = () => {
        setBurgerMenuOpen(!burgerMenuOpen);
    };

    const handleClose = () => {
        setBurgerMenuOpen(false);
    };

    return (
        <nav className={styles.navBar}>
            <AiOutlineMenu className={styles.burgerIcon} onClick={toggleBurgerMenu}/>
            <Drawer
                anchor="left"
                open={burgerMenuOpen}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#1f1a3a',
                        color: '#ffd700',
                    },
                }}
            >
                <List
                    sx={{width: 250}}
                    role="presentation"
                    onClick={handleClose}
                    onKeyDown={handleClose}
                >
                    <ListItem button>
                        <RouterNavLink
                            to="/"
                            exact={true.toString()}
                            className={(location) => (location.pathname === "/" ? styles.active : "")}
                            sx={{textDecoration: 'none', width: '100%'}}
                        >
              <span style={{color: '#ffd700'}}>
                <ListItemText primary="Home"/>
              </span>
                        </RouterNavLink>
                    </ListItem>
                    <ListItem button>
                        <RouterNavLink
                            to="/quizworld"
                            className={(location) => (location.pathname === "/quizworld" ? styles.active : "")}
                            sx={{textDecoration: 'none', width: '100%'}}
                        >
              <span style={{color: '#ffd700'}}>
                <ListItemText primary="Quiz World"/>
              </span>
                        </RouterNavLink>
                    </ListItem>
                    <ListItem button>
                        <RouterNavLink
                            to="/setup-web3"
                            className={(location) => (location.pathname === "/setup-web3" ? styles.active : "")}
                            sx={{textDecoration: 'none', width: '100%'}}
                        >
              <span style={{color: '#ffd700'}}>
                <ListItemText primary="Set Up Web3"/>
              </span>
                        </RouterNavLink>
                    </ListItem>
                    <ListItem button>
                        <RouterNavLink
                            to="/terms-and-conditions"
                            className={(location) => (location.pathname === "/terms-and-conditions" ? styles.active : "")}
                            sx={{textDecoration: 'none', width: '100%'}}
                        >
              <span style={{color: '#ffd700'}}>
                <ListItemText primary="Terms & Conditions"/>
              </span>
                        </RouterNavLink>
                    </ListItem>
                    <ListItem button>
                        <RouterNavLink
                            to="/privacy-policy"
                            className={(location) => (location.pathname === "/privacy-policy" ? styles.active : "")}
                            sx={{textDecoration: 'none', width: '100%'}}
                        >
              <span style={{color: '#ffd700'}}>
                <ListItemText primary="Privacy Policy"/>
              </span>
                        </RouterNavLink>
                    </ListItem>
                </List>
            </Drawer>
        </nav>
    );
};

export default Navbar;
