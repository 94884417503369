import { useEffect, useCallback } from 'react';
import Web3 from "web3";

export const useSetWeb3Account = (setWeb3, setAccount) => {
    const initWeb3 = useCallback(async () => {
        const ethereum = window.ethereum;
        if (ethereum) {
            const web3Instance = new Web3(ethereum);
            setWeb3(web3Instance);

            const accounts = await web3Instance.eth.getAccounts();
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            }

            // Listen for account changes
            ethereum.on('accountsChanged', (newAccounts) => {
                if (newAccounts.length > 0) {
                    setAccount(newAccounts[0]);
                } else {
                    setAccount(null); // Clear the account state if no accounts are connected
                }
            });
        }
    }, [setAccount, setWeb3]);

    useEffect(() => {
        initWeb3();
        // Clean up the accountsChanged event listener when the component is unmounted
        return () => {
            if (window.ethereum) {
                window.ethereum.removeAllListeners('accountsChanged');
            }
        };
    }, [initWeb3]);
};
