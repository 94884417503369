import {Grid, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../App.module.css';
import {useMintEarlyExplorerNft} from "../../hooks/UseMintEarlyExplorerNft";
import {UseFetchNftEligibility} from "../../hooks/UseFetchNftEligibility";
import {useState} from 'react';
import {useMintAllTimeChampionNft} from "../../hooks/UseMintAllTimeChampionNft";
import {useMintMonthlyChampionNft} from "../../hooks/UseMintMonthlyChampionNft";
import {convertKeyToDateString} from "../../functions/ConvertKeyToDateString";

const NftRealm = ({realm, quizRealms, onSelectRealm, selectedRealm, etherQuizWorldContract, account}) => {
    const handleClick = () => {
        onSelectRealm(realm);
    };

    const closeRealm = (event) => {
        event.stopPropagation();
        onSelectRealm(null)
    };

    const [eligibleEarlyExplorer, setEligibleEarlyExplorer] = useState(false);

    const [eligibleMonthlyLeaderboardEntry, setEligibleMonthlyLeaderboardEntry] = useState([]);
    const [eligibleRealmsAllTimeChampionEntry, setEligibleRealmsAllTimeChampionEntry] = useState([]);

    UseFetchNftEligibility(account, quizRealms, selectedRealm, etherQuizWorldContract, setEligibleEarlyExplorer,
        setEligibleMonthlyLeaderboardEntry, setEligibleRealmsAllTimeChampionEntry);

    const mintEarlyExplorerNft = useMintEarlyExplorerNft(account, etherQuizWorldContract)
    const mintMonthlyChampionNft = useMintMonthlyChampionNft(account, etherQuizWorldContract)
    const mintAllTimeChampionNft = useMintAllTimeChampionNft(account, etherQuizWorldContract)


    const mintAndSetEarlyExplorerEligibility = () => mintEarlyExplorerNft().then(() => setEligibleEarlyExplorer(false));

    const mintAndSetMonthlyEligibility = (entry) => {
        return mintMonthlyChampionNft(entry)
            .then(() => {
                setEligibleMonthlyLeaderboardEntry(prevEntries =>
                    prevEntries.filter(e => e !== entry)
                );
            });
    };

    const mintAndSetAllTimeEligibility = (entry) => {
        return mintAllTimeChampionNft(entry)
            .then(() => {
                setEligibleRealmsAllTimeChampionEntry(prevEntries =>
                    prevEntries.filter(e => e !== entry))
            });
    };

    return account && (
        <div onClick={handleClick} className={styles.realmContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4 " className={styles.realmName}>{realm}</Typography>
                        </Grid>
                        {selectedRealm === realm && (
                            <Grid item>
                                <IconButton onClick={closeRealm}>
                                    <CloseIcon className={styles.closeIcon}/>
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {selectedRealm === realm && (
            <Grid>
                <div className={styles.instructions}>
                    <h2>There are 3 Types of NFT you could be eligible for:</h2>
                    <ol>
                        <li>The Early Explorer NFT: If you play any Quiz World game in the first 30 days of release and score at least 1 point,
                            you are able to mint this NFT. It will provide a 50% discount on the entry fee of any future Quiz World game</li>
                        <li>The Monthly Realm Leaderboard NFT: At the end of each month between 13:00 and 23:00 UTC a snapshot of
                            the current leaderboard for each realm is taken to create a Realm Month Leaderboard. If you place 1st on
                            any monthly realm leaderboard you are eligible to mint this NFT. It will provide a 50% discount on the entry fee
                            of any future Quiz World game for 1st place decreasing by 5% in each position down to 10th.
                            If you hold more than 1 of these NFTs the discount will apply the highest position discount.
                        </li>
                        <li>The All Time Realm Leaderboard NFT: When you submit a score at the end of a game, if you score in the
                            top 10 of that realm then your entry will be recorded on the Realm Champions Leaderboard.
                            If you hold a position on the Champions Leaderboard for more than 30 days you are eligible to mint this NFT.
                            A 1st place position will provide a 50% discount on the entry fee
                            of any future Quiz World game for 1st place decreasing by 5% in each position down to 10th.
                            If you hold more than 1 of these NFTs the discount will apply the highest position discount.</li>
                    </ol>
                    <h3>NFT discounts stack. As an example if you hold at least 1 of each of the types of NFT with a position
                        of 1st place for the Month and Champion types you would receive the following entry fee discount:
                        Base_Entry(0.0005) - Early_Explorer discount(50%) - Monthly_Champion discount(50%)
                        - All_Time_Champion discount(50%) = Discounted_Base_Entry (0.0000625)</h3>
                    <h3>For now we aim to keep the standard Base Entry fee at the equivalent $1, in that light we will update the
                    the Base_Entry once per month after each leaderboard snapshot as needed.</h3>
                </div>
            </Grid>
            )}
            {selectedRealm === realm && (
                <Grid container className={styles.realmContainer} alignItems="center" justifyContent="space-between"
                      spacing={2}>
                    {eligibleEarlyExplorer &&
                        <Grid container alignItems="center" justifyContent="center" spacing={1}>
                            <button className={styles.button} onClick={mintAndSetEarlyExplorerEligibility}>
                                Mint Early Explorer NFT
                            </button>
                        </Grid>}
                    {
                        eligibleMonthlyLeaderboardEntry.map((entry, index) => (
                            <Grid container key={index} alignItems="center" justifyContent="center" spacing={1}>
                                <button
                                    className={styles.button}
                                    onClick={() => mintAndSetMonthlyEligibility(entry)}
                                >
                                    Mint {convertKeyToDateString(entry.key)} {entry.realm} Champion NFT for
                                    Position {entry.pos}
                                </button>
                            </Grid>
                        ))
                    }
                    { eligibleRealmsAllTimeChampionEntry.map((entry, index) => (
                            <Grid container key={index} alignItems="center" justifyContent="center" spacing={1}>
                                <button className={styles.button} onClick={() => mintAndSetAllTimeEligibility(entry)}>
                                    Mint {entry.realm} All Time Champion NFT for Position {entry.pos}
                                </button>
                            </Grid>
                        ))
                    }
                </Grid>
            )}
        </div>
    );
};

export default NftRealm;
