import {httpsCallable} from "firebase/functions";

export const verifyQuizAnswersAndSign = async (functions, realm, account, answers, timeTaken) => {
    const verifyAndSignFunction = httpsCallable(functions, 'verifyQuizAnswersAndSign');
    try {
        const result = await verifyAndSignFunction({realm, account, answers, timeTaken});
        return result.data;
    } catch (error) {
        console.error("Error calling verifyAndSign:", error);
        throw error;
    }
}
