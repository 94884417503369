export const shuffleOptions = (question) => {
    const propertyNames = ['a', 'b', 'c', 'd']
    const options = propertyNames.map((key) => ({ key, value: question[key] }));

    const shuffleArray = (array) => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    return shuffleArray(options);
};
