import { useState, useEffect, useMemo } from 'react';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

export const useAppHeight = () => {
    const [appHeight, setAppHeight] = useState(window.innerHeight);

    const updateAppHeight = useMemo(() => debounce(() => {
        setAppHeight(document.documentElement.scrollHeight);
    }, 100), []);

    useEffect(() => {
        const handleResize = () => {
            updateAppHeight();
        };

        const handleOrientationChange = () => {
            updateAppHeight();
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);
        updateAppHeight();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [updateAppHeight]);

    return appHeight;
};
