import {httpsCallable} from "firebase/functions";
import {shuffleOptions} from "./ShuffleOptions";

export const getQuizQuestions = async (functions, realm, challengeQuestionsValue) => {
    try {
        const getQuestionsFunction = httpsCallable(functions, 'getQuizQuestions');
        const generatedQuestions = await getQuestionsFunction({realm, challengeQuestionsValue});
        return generatedQuestions.data.map((question) => ({
            ...question,
            shuffledOptions: shuffleOptions(question),
        }));
    } catch (error) {
        console.error("Error calling getQuestions:", error);
        throw error;
    }
};
