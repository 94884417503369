import React, { useMemo, useCallback, useEffect } from 'react';

const generateStars = (count, styles, appHeight) => {
    const stars = [];
    const minSize = 1;
    const maxSize = 2;
    const minDelay = 0;
    const maxDelay = 2;
    const minDuration = 2;
    const maxDuration = 7;

    for (let i = 0; i < count; i++) {
        const left = Math.random() * 100 + 'vw';
        const top = Math.random() * appHeight + 'px';
        const size = Math.random() * (maxSize - minSize) + minSize;
        const delay = Math.random() * (maxDelay - minDelay) + minDelay + 's';
        const duration = Math.random() * (maxDuration - minDuration) + minDuration + 's';

        stars.push(
            <div
                key={i}
                className={styles.star}
                style={{
                    left,
                    top,
                    width: size + 'px',
                    height: size + 'px',
                    animationDelay: delay,
                    animationDuration: duration,
                }}
            ></div>
        );
    }

    return stars;
};

export const Stars = (count, styles, appHeight) => {
    const generateMemoizedStars = useCallback(() => generateStars(count, styles, appHeight), [count, styles, appHeight]);

    const stars = useMemo(() => generateMemoizedStars(), [generateMemoizedStars]);

    useEffect(() => {
        const handleResize = () => {
            generateMemoizedStars();
        };

        const handleOrientationChange = () => {
            generateMemoizedStars();
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [generateMemoizedStars]);

    return <div>{stars}</div>;
};
