export const shortenPublicKey = (publicKey, prefixLength = 4, suffixLength = 4) => {
    if (typeof publicKey !== 'string') {
        throw new Error('Public key must be a string.');
    }

    const maxLength = prefixLength + suffixLength;
    if (publicKey.length <= maxLength) {
        return publicKey;
    }

    const prefix = publicKey.slice(0, prefixLength);
    const suffix = publicKey.slice(-suffixLength);
    return `${prefix}...${suffix}`;
};
