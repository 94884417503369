import React from 'react';
import styles from "../App.module.css";
import EthanImage from '../resources/Ethan.svg';
import PortalImage from '../resources/portal.svg';
import {Stars} from "../functions/StarUtils";
import {useAppHeight} from "../functions/UseAppHeight";

const Home = () => {
    const starCount = 1000;
    return (
        <div className={styles.container}>
            {Stars(starCount, styles, useAppHeight())}
            <h1 className={styles.title}>EtherGameHub</h1>
            <div className={styles.content}>
                <div className={styles.mascotColumn}>
                    <img src={PortalImage} alt="Spinning Portal"
                         className={styles.portal}/> {/* Add the portal image */}
                    <img src={EthanImage} alt="Ethan the Mascot" className={styles.mascot}/>
                </div>
                <div className={styles.textColumn}>
                    <div className={styles.welcomeText}>
                        <h1>🎉 Welcome, Ethernauts! 🎉</h1>
                        <h2>I.. am Ethan...</h2>
                        <p>
                            Please, try not to be intimidated by my remarkable intellect, my impeccable good looks, or
                            my unparalleled wit... I am but your humble host!
                        </p>
                        <h1>🕹️ Welcome to EtherGameHub! 🕹️</h1>
                        <h2>Where Fun Meets Blockchain</h2>
                        <p>
                            At EtherGameHub, we're passionate about creating fun, exciting, and engaging games built on
                            the <a href="https://ethereum.org/en/" target="_blank"
                                   rel="noopener noreferrer">Ethereum</a> blockchain. </p>
                        <p> We are using <a href="https://arbitrum.foundation" target="_blank"
                                            rel="noopener noreferrer">Arbitrum</a> as our L2
                            platform of choice
                        </p>
                        <p> Right now, we only have one world for you to explore, but worry not! We have big plans for
                            the future.</p>
                        <h3>Our Vision and Future Plans</h3>
                        <p> As our platform grows, we will use the proceeds to develop more diverse, complex, and
                            entertaining games for you and your fellow crypto enthusiasts. So, stay tuned for the
                            exciting journey ahead!</p>
                        <h3>A Secure and Decentralized Gaming Experience</h3>
                        <p>
                            We're committed to offering a secure and decentralized gaming experience where users can
                            play and compete with one another responsibly. </p>
                        <p> We believe loyal gamers should be rewarded, when you play our games you will have the
                            chance to unlock rewards and discounts for the platform</p>
                        <h3>Protect Your Crypto Assets</h3>
                        <p>
                            Prioritizing the security of your cryptocurrency assets is crucial. Remember to always keep
                            your private keys safe and never share them with anyone. To learn more about protecting your
                            crypto assets and understanding the rules of using our games, please refer to our <a
                            href="/terms-and-conditions">Terms and Conditions</a> page.
                        </p>
                        <h2>Join Us Today and Embrace the Adventure!</h2>
                        <p>
                            Thank you for visiting EtherGameHub! We're excited to have you on board, and we can't wait
                            to
                            see you in action in our games. </p>
                        <p>Good luck, and let the fun begin! 🚀 </p>
                    </div>

                </div>
            </div>
        </div>
    )
        ;
};

export default Home;
