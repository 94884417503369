import { useEffect, useCallback } from 'react';

/**
 * useCheckOwner - A custom React Hook to check if a given account has the
 * default admin role for a particular contract, and update the ownership state.
 *
 * @param etherQuizWorldContract
 * @param {string} account - The account to check for admin role.
 * @param setIsQuizWorldOwner
 * @param setIsQuizWorldAdmin
 */
export const useCheckOwnerAdmin = (etherQuizWorldContract, account,
                                   setIsQuizWorldOwner, setIsQuizWorldAdmin) => {
    const checkEtherQuizWorldContractOwnership = useCallback(async () => {
        if (etherQuizWorldContract && account) {
            try {
                const ownerRole = await etherQuizWorldContract.methods.getOwnerRole().call();
                const hasOwnerRole = await etherQuizWorldContract.methods.hasRole(ownerRole, account).call();
                setIsQuizWorldOwner(hasOwnerRole);
            } catch (error) {
                console.error('Error checking ownership:', error);
            }
        }
    }, [etherQuizWorldContract, account, setIsQuizWorldOwner]);


    const checkEtherQuizWorldContractAdmin = useCallback(async () => {
        if (etherQuizWorldContract && account) {
            try {
                const adminRole = await etherQuizWorldContract.methods.getAdminRole().call();
                const hasAdminRole = await etherQuizWorldContract.methods.hasRole(adminRole, account).call();
                setIsQuizWorldAdmin(hasAdminRole);
            } catch (error) {
                console.error('Error checking ownership:', error);
            }
        }
    }, [etherQuizWorldContract, account, setIsQuizWorldAdmin]);

    useEffect(() => {
        checkEtherQuizWorldContractOwnership();
        checkEtherQuizWorldContractAdmin();
    }, [checkEtherQuizWorldContractAdmin, checkEtherQuizWorldContractOwnership]);
};
