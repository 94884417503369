import React from "react";
import styles from "../App.module.css";
import {connectWallet} from "../functions/ConnectWallet";
import {shortenPublicKey} from "../functions/ShortenPublicKey"

const ConnectWalletButton = ({account, setAccount}) => {

    const clickConnect = () => {
        connectWallet(setAccount)
    }

    return account ? (
        <p className={styles.info}>Connected: {shortenPublicKey(account)}</p>
    ) : (
        <button className={styles.button} onClick={clickConnect}>
            Connect Wallet
        </button>
    );
};

export default ConnectWalletButton;
