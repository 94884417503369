import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from "./pages/Home";
import TermsAndConditions from './pages/TermsAndConditions';
import NavBar from "./NavBar";
import QuizWorld from "./pages/QuizWorld";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SetUpWeb3 from "./pages/SetUpWeb3";

import {getAnalytics} from "firebase/analytics";
import FirebaseStart from "./FirebaseStart";
import QuizWorldAdmin from "./pages/QuizWorldAdmin";
import {useState} from "react";
import {useSetWeb3Account} from "./hooks/UseSetWeb3Account";
import {useGetEtherQuizWorldContracts} from "./hooks/UseGetEtherQuizWorldContracts";


function App() {

    const {app, functions} = FirebaseStart();
    const analytics = getAnalytics(app);

    const [account, setAccount] = useState();

    const [web3, setWeb3] = useState();
    const [etherQuizWorldContract, setEtherQuizWorldContract] = useState();

    useSetWeb3Account(setWeb3, setAccount);
    useGetEtherQuizWorldContracts(web3, account, setEtherQuizWorldContract)

    return (
        <Router>
            <div>
                <NavBar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/quizworld" element={<QuizWorld etherQuizWorldContract={etherQuizWorldContract} account={account} setAccount={setAccount} functions={functions}/>}/>
                    <Route path="/quizworld-admin" element={<QuizWorldAdmin etherQuizWorldContract={etherQuizWorldContract} account={account} setAccount={setAccount}/>}/>
                    <Route path="/setup-web3" element={<SetUpWeb3/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
