import { useEffect, useCallback } from 'react';

export const useFetchChallengeDuration = (etherQuizWorldContract, account, setTime) => {
    const fetchChallengeDuration = useCallback(async () => {
        if (etherQuizWorldContract && account) {
            const duration = await etherQuizWorldContract.methods.getChallengeDuration().call({from: account});
            setTime(duration);
        }
    }, [etherQuizWorldContract, account, setTime]);

    useEffect(() => {
        fetchChallengeDuration();
    }, [fetchChallengeDuration]);
};
