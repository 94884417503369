import { useEffect, useCallback } from 'react';
import { ETHERQUIZWORLD_CONTRACT_ABI, ETHERQUIZWORLD_CONTRACT_ADDRESS } from "../config/quizworldConfig";

/**
 * useGetEtherQuizWorldContracts - A custom React Hook to initialize the EtherQuizWorld
 * and RealmChampions NFT contracts, and update the respective contract states.
 *
 * @param {Object} web3 - The web3 instance to interact with the blockchain.
 * @param {string} account - The user's account.
 * @param {Function} setEtherQuizWorldContract - Function to update the EtherQuizWorld contract state.
 */
export const useGetEtherQuizWorldContracts = (web3, account, setEtherQuizWorldContract) => {
    const initializeContracts = useCallback(() => {
        if (web3 && account) {
            const contractInstance = new web3.eth.Contract(
                ETHERQUIZWORLD_CONTRACT_ABI,
                ETHERQUIZWORLD_CONTRACT_ADDRESS
            );
            setEtherQuizWorldContract(contractInstance);
        }
    }, [web3, account, setEtherQuizWorldContract]);

    useEffect(() => {
        initializeContracts();
    }, [initializeContracts]);
};
