import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Web3 from 'web3';
import Web3Context from './Web3Context';

const web3 = new Web3(window.ethereum);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Web3Context.Provider value={web3}>
        <App />
    </Web3Context.Provider>
);

reportWebVitals();
