export const convertKeyToDateString = (key) => {
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const year = key.toString().slice(0, 4);
    const month = key.toString().slice(4, 6);
    const monthIndex = parseInt(month, 10) - 1;

    return `${monthNames[monthIndex]} ${year}`;
}
