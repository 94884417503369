import React from 'react';

import styles from "../App.module.css";

const TermsAndConditions = () => {
    return (
        <div className={styles.container}>
            <div className={styles.termsAndConditions}>
                <div className={styles.text}>
                    <h2>Terms and Conditions</h2>

                    <h3>Entire Agreement</h3>
                    <p>
                        These Terms, together with any additional terms and conditions referenced herein, constitute the
                        entire agreement between you and the platform's developers and contract owners regarding the
                        platform and its games and supersede any prior agreements, whether written or oral, on the
                        subject matter.
                    </p>

                    <h3>Introduction</h3>
                    <p>
                        Please read these Terms and Conditions ("Terms") carefully before using any of the smart
                        contract games
                        ("Games") operated on the Ethereum blockchain and available on our platform. By interacting with
                        any of the Games, you agree to be
                        bound by
                        these Terms. If you do not agree with any part of these Terms or if the use of the Games is
                        prohibited in
                        your jurisdiction, you must not use any of the Games on the platform.
                    </p>

                    <h3>Eligibility</h3>
                    <p>To be eligible to participate in the Games, you must:</p>
                    <ol>
                        <li>Be at least 18 years of age or the age of legal majority in your jurisdiction, whichever is
                            greater.
                        </li>
                        <li>Be legally capable of entering into a binding agreement.</li>
                        <li>Comply with all applicable laws and regulations in your jurisdiction, including, but not
                            limited to,
                            any restrictions on the use of digital assets, and any tax obligations resulting
                            from
                            your participation in the Games.
                        </li>
                    </ol>

                    <h3>User Conduct</h3>
                    <p>
                        You agree to use the platform and its games responsibly and in compliance with all applicable
                        laws and regulations. You agree not to engage in any activity that may harm the platform, its
                        users, or the Ethereum network. This includes, but is not limited to, spamming, hacking,
                        cheating, or using automated software or bots to interact with the platform or its games.
                    </p>

                    <h3>User-Generated Content</h3>
                    <p>
                        The platform may allow users to create, upload, or share content, such as text, images, or
                        videos. You are solely responsible for any content you create, upload, or share and must ensure
                        it complies with applicable laws and regulations. The platform's developers and contract owners
                        reserve the right to remove any content that violates these Terms or is deemed offensive,
                        illegal, or harmful to others.
                    </p>

                    <h3>Game Rules</h3>
                    <p>Each Game on the platform operates according to its own unique set of rules. Before participating
                        in any Game, you should familiarize yourself with the specific rules associated with it. The
                        platform offers various types of Games, each with its own mechanics, rewards, and potential
                        risks.</p>

                    <h3>Risk Acknowledgment</h3>
                    <p>
                        By participating in any of the Games, you acknowledge and agree that:
                    </p>
                    <ol>
                        <li>
                            You are aware of the risks associated with using blockchain-based smart contracts and
                            digital assets, including, but not limited to, the risk of software bugs, hacks, and other
                            vulnerabilities.
                        </li>
                        <li>
                            You are solely responsible for managing your digital assets and any private keys associated
                            with your blockchain wallet.
                        </li>
                        <li>
                            You accept the risk of any losses or damages resulting from your participation in any of the
                            Games, including any direct, indirect, incidental, or consequential losses.
                        </li>
                        <li>
                            You understand the importance of following best practices for cryptocurrency key security,
                            including, but not limited to:
                            <ol>
                                <li>
                                    Keeping your private keys confidential and not sharing them with anyone.
                                </li>
                                <li>
                                    Storing your private keys securely, using secure digital wallets or hardware
                                    wallets, and keeping backup copies in safe locations.
                                </li>
                                <li>
                                    Regularly updating your wallet software and any associated security measures, such
                                    as antivirus software or firewalls.
                                </li>
                                <li>
                                    Being cautious of phishing attacks, malicious websites, and suspicious emails, and
                                    verifying the authenticity of any communication related to your digital assets.
                                </li>
                            </ol>
                        </li>
                        <li>
                            You acknowledge that the platform's developers, contract owners, and any affiliated parties
                            are not responsible for any loss or theft of your private keys or digital assets resulting
                            from your failure to follow best practices for cryptocurrency key security.
                        </li>
                        <li>
                            You understand that any loss of your private keysmay result in the permanent loss of your
                            digital assets associated with those keys, and that the platform's developers, contract
                            owners, and any affiliated parties have no ability to recover your lost keys or digital
                            assets.
                        </li>
                    </ol>
                    <h3>Limitation of Liability</h3>
                    <p>
                        In no event shall the platform's developers, contract owners, or any affiliated parties be
                        liable for
                        any
                        damages or losses resulting from your use of or inability to use any of the Games on the
                        platform. This limitation of
                        liability
                        applies to the fullest extent permitted by law.
                    </p>

                    <h3>Platform Availability</h3>
                    <p>
                        The platform's developers and contract owners do not guarantee that the platform or its games
                        will be available or accessible at all times. There may be instances when the platform or its
                        games are temporarily unavailable due to maintenance, updates, or other reasons. You acknowledge
                        and agree that the platform's developers and contract owners are not liable for any losses or
                        damages resulting from the unavailability of the platform or its games.
                    </p>

                    <h3>Indemnification</h3>
                    <p>
                        You agree to indemnify, defend, and hold harmless the platform's developers, contract owners,
                        and any
                        affiliated parties from and against any and all claims, losses, damages, liabilities, and
                        expenses,
                        including reasonable attorneys' fees, arising out of your use of any of the Games on the
                        platform or any breach of these
                        Terms.
                    </p>

                    <h3>Governing Law</h3>
                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of the United
                        Kingdom,
                        without regard to its conflict of law provisions.
                    </p>

                    <h3>Intellectual Property Rights</h3>
                    <p>
                        All content on the platform, including but not limited to, the games, text, graphics, images,
                        logos, and software, is the property of the platform or its licensors and is protected by
                        copyright, trademark, and other intellectual property laws. You agree not to copy, distribute,
                        modify, reproduce, or create derivative works from any content on the platform without the prior
                        written consent of the platform or its licensors.
                    </p>

                    <h3>Third-Party Links and Services</h3>
                    <p>
                        The platform may contain links to third-party websites or services that are not owned or
                        controlled by the platform's developers or contract owners. You acknowledge and agree that the
                        platform's developers and contract owners have no control over, and assume no responsibility
                        for, the content, privacy policies, or practices of any third-party websites or services. By
                        using the platform, you expressly release the platform's developers and contract owners from any
                        and all liability arising from your use of any third-party website or service.
                    </p>

                    <h3>Dispute Resolution</h3>
                    <p>
                        In the event of any dispute arising out of or in connection with these Terms, the parties agree
                        to negotiate in good faith to resolve the dispute amicably. If the dispute cannot be resolved
                        through negotiation, the parties agree to submit the dispute to binding arbitration under the
                        rules of the London Court of International Arbitration, with the arbitration to be conducted in
                        London, United Kingdom.
                    </p>

                    <h3>Severability</h3>
                    <p>
                        If any provision of these Terms is found to be invalid or unenforceable by a court of competent
                        jurisdiction, the remaining provisions of these Terms shall remain in full force and effect, and
                        the invalid or unenforceable provision shall be deemed replaced by a valid and enforceable
                        provision that most closely reflects the original intent of the parties.
                    </p>

                    <h3>Modifications to the Platform and Games</h3>
                    <p>
                        The platform's developers and contract owners reserve the right to modify, suspend, or
                        discontinue the platform or any of its games at any time without prior notice. You agree that
                        the platform's developers and contract owners shall not be liable for any losses or damages
                        resulting from such modifications, suspensions, or discontinuations.
                    </p>

                    <h3>No Warranty</h3>
                    <p>
                        The platform and its games are provided "as is" and "as available" without any warranties of any
                        kind, either express or implied, including but not limited to, implied warranties of
                        merchantability, fitness for a particular purpose, and non-infringement. The platform's
                        developers and contract owners do not warrant that the platform or its games will be error-free,
                        uninterrupted, secure, or free from viruses or other harmful components.
                    </p>

                    <h3>Force Majeure</h3>
                    <p>
                        The platform's developers and contract owners shall not be liable for any delay or failure in
                        performance resulting from causes beyond their reasonable control, including but not limited to,
                        acts of God, acts of government, labor disputes, acts of terrorism, or disruptions in the
                        Ethereum network.
                    </p>

                    <h3>Assignment</h3>
                    <p>
                        You may not assign or transfer your rights or obligations under these Terms without the prior
                        written consent of the platform's developers and contract owners. The platform's developers and
                        contract owners may assign or transfer their rights and obligations under these Terms without
                        restriction.
                    </p>

                    <h3>Privacy Policy</h3>
                    <p>
                        By using the platform and its games, you agree to the collection, use, and sharing of your
                        information in accordance with the platform's Privacy Policy, which may be updated from time to
                        time. Please review the Privacy Policy to understand how your personal information will be
                        handled.
                    </p>

                    <h3>Changes to Terms</h3>
                    <p>
                        We reserve the right to modify or replace these Terms at any time. We will notify users of any
                        changes
                        by updating the terms on the website associated with the platform. By continuing to use any of
                        the Games on the platform
                        after the
                        updated Terms are posted, you agree to be bound by the revised Terms.
                    </p>

                    <h3>Contact Information</h3>
                    <p>
                        If you have any questions or concerns regarding these Terms, please contact the contract owner
                        at the
                        email address provided on the website associated with the platform.
                    </p>

                    <a href="mailto: dev@ethergamehub.com">Email: dev@ethergamehub.com</a>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
