import { useCallback } from 'react';

export const useSubmitQuizAnswer = (answers, currentQuestion, questions, dispatch) => {
    return useCallback((answerKey) => {
        dispatch({
            type: 'setAnswers',
            payload: [
                ...answers,
                {
                    questionIndex: questions[currentQuestion].index,
                    answerKey: answerKey,
                    type: questions[currentQuestion].type,
                },
            ],
        });
        dispatch({ type: 'setCurrentQuestion', payload: currentQuestion + 1 });
    }, [answers, currentQuestion, questions, dispatch]);
};
