import React, {useState, useEffect} from 'react';
import styles from '../App.module.css';
import {shortenPublicKey} from "../functions/ShortenPublicKey";
import {Grid} from "@mui/material";

function Leaderboard({title, getLeaderboard, realm, scoreSubmitted, displayDate}) {
    const [leaderboard, setLeaderboard] = useState([]);

    useEffect(() => {
        async function fetchLeaderboard() {
            const leaderboardData = await getLeaderboard;
            setLeaderboard(leaderboardData);
        }

        fetchLeaderboard();
    }, [getLeaderboard, realm, scoreSubmitted]);

    const dateFromTimestamp = (timestamp) =>{
        return new Date(timestamp * 1000).toDateString()
    };

    return (
        <div className={styles.container}>
            <Grid container spacing={1} className={styles.titleContainer}>
            <h1 className={styles.title}>{title}</h1>
            </Grid>
            <div className={styles.content}>
                <Grid container className={styles.realmContainer} alignItems="center" justifyContent="space-between">
                <table className={styles.leaderboardTable}>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>User</th>
                        <th>Score</th>
                        <th>Time Taken</th>
                        {displayDate && (<th>Date</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {leaderboard.map((entry, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{shortenPublicKey(entry.user)}</td>
                            <td>{entry.score}</td>
                            <td>{entry.time}s</td>
                            {displayDate && ( <td>{dateFromTimestamp(entry.timestamp)}</td>)}
                        </tr>
                    ))}
                    </tbody>
                </table>
                </Grid>
            </div>
        </div>
    );
}

export default Leaderboard;
