import {getFunctions} from "firebase/functions";
import firebaseConfig from "./FirebaseConfig";
import {initializeApp} from "firebase/app";
import FirebaseEmulators from "./FirebaseEmulators";
import {initializeAppCheck, ReCaptchaEnterpriseProvider} from "firebase/app-check";

export default function FirebaseStart() {
    const config = firebaseConfig;
    const app = initializeApp(config);
    const functions = getFunctions(app, "europe-west2");

    if (process.env.NODE_ENV !== 'production') {
        FirebaseEmulators(functions);
        // eslint-disable-next-line no-restricted-globals
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider("6LepcYwlAAAAAPY5Hw4rzCf_cVKiwW7it2Go61Jb"),
        isTokenAutoRefreshEnabled: true
    });
    return {app, functions}
}
