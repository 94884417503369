import React from 'react';

import styles from "../App.module.css";

const PrivacyPolicy = () => {
    return (<div className={styles.container}>
        <div className={styles.termsAndConditions}>
            <div className={styles.text}>
                <h2>Privacy Policy</h2>
                <h3>Introduction</h3>
                <p>
                    This Privacy Policy explains how we collect, use, disclose, and protect your personal
                    information when you use our Ethereum Smart Contract Game Platform website ("the Platform"). By
                    using the Platform, you agree to the collection, use, and disclosure of your personal
                    information in accordance with this Privacy Policy. If you do not agree with any part of this
                    Privacy Policy, you must not use the Platform. This Privacy Policy is compliant with the General
                    Data Protection Regulation (GDPR).
                </p>
                <h3>Information We Collect</h3>
                <p>
                    We may collect the following types of personal information when you use the Platform:
                </p>
                <ol>
                    <li>Personal Identifiers: such as your name, email address, and Ethereum wallet address.</li>
                    <li>Usage Data: information about how you use the Platform, such as the games you play, the
                        transactions you make, and the content you interact with.
                    </li>
                    <li>Technical Data: information about your device, browser, and IP address.</li>
                    <li>Communications Data: information you provide when contacting us or participating in any
                        interactive features of the Platform.
                    </li>
                </ol>
                <h3>How We Use Your Information</h3>
                <p>
                    We use the personal information we collect for the following purposes:
                </p>
                <ol>
                    <li>To provide and maintain the Platform, its games, and its services.</li>
                    <li>To improve and personalize your experience on the Platform.</li>
                    <li>To communicate with you about the Platform, its games, and any support or inquiries you may
                        have.
                    </li>
                    <li>To enforce our Terms and Conditions and to protect the security and integrity of the
                        Platform.
                    </li>
                    <li>To comply with legal and regulatory requirements.</li>
                </ol>
                <h3>Disclosure of Your Information</h3>
                <p>
                    We may share your personal information with third parties in the following circumstances:
                </p>
                <ol>
                    <li>With your consent, or at your direction.</li>
                    <li>With our service providers, who help us provide and maintain the Platform and its
                        services.
                    </li>
                    <li>As required by law, regulation, or legal process.</li>
                    <li>When we believe in good faith that disclosure is necessary to protect the rights, property,
                        or safety of our users, the Platform, or the public.
                    </li>
                </ol>
                <h3>Data Security</h3>
                <p>
                    We take the security of your personal information seriously and implement appropriate technical
                    and organizational measures to protect your personal information from unauthorized access, use,
                    disclosure, or destruction. However, please note that no method of electronic transmission or
                    storage is 100% secure, and we cannot guarantee the absolute security of your personal
                    information.
                </p>
                <h3>Retention of Your Information</h3>
                <p>
                    We will retain your personal information only for as long as necessary to fulfill the purposes
                    for which it was collected, to comply with our legal obligations, and to resolve any disputes
                    that may arise.
                </p>
                <h3>Your Rights under GDPR</h3>
                <p>
                    If you are a resident of the European Union, you have the following rights under the GDPR
                    regarding your personal information:
                </p>
                <ol>
                    <li>The right to access your personal information and to obtain a copy of it.</li>
                    <li>The right to rectify any inaccurate or incomplete personal information.</li>
                    <li>The right to erase your personal information, subject to certain limitations.</li>
                    <li>The right to restrict the processing of your personal information.</li>
                    <li>The right to object to the processing of your personal information.</li>
                    <li>The right to data portability, which allows you to receive your personal information in a
                        structured, commonly used, and machine-readable format and to transmit it to another data
                        controller.
                    </li>
                    <li>The right to withdraw your consent to the processing of your personal information at any
                        time, where applicable.
                    </li>
                    <li>The right to lodge a complaint with a supervisory authority if you believe that the
                        processing of your personal information infringes on your rights under the GDPR.
                    </li>

                </ol>
                <p>
                    To exercise any of your rights under the GDPR, please contact us using the contact information
                    provided in the "Contact Information" section of this Privacy Policy. We will respond to your
                    request in accordance with the GDPR.
                </p>
                <h3>Changes to This Privacy Policy</h3>
                <p>
                    We reserve the right to modify or update this Privacy Policy at any time. We will notify users
                    of any changes by updating the Privacy Policy on the Platform. By continuing to use the Platform
                    after the updated Privacy Policy is posted, you agree to be bound by the revised Privacy Policy.
                </p>
            <p>
                To exercise any of your rights under the GDPR, please contact us using the contact information
                provided in the "Contact Information" section of this Privacy Policy. We will respond to your
                request in accordance with the GDPR.
            </p>
            <h3>Changes to This Privacy Policy</h3>
            <p>
                We reserve the right to modify or update this Privacy Policy at any time. We will notify users of
                any changes by updating the Privacy Policy on the Platform. By continuing to use the Platform after
                the updated Privacy Policy is posted, you agree to be bound by the revised Privacy Policy.
            </p>
                <h3>Contact Information</h3>
                <p>
                    If you have any questions or concerns regarding this Privacy Policy, please contact us using the
                    contact information provided on the Platform.
                </p>
                <a href="mailto: dev@ethergamehub.com">Email: dev@ethergamehub.com</a>
        </div>
    </div>
</div>
)
    ;
}

export default PrivacyPolicy;
