import React from 'react';
import styles from "../App.module.css";

const SetUpWeb3 = () => {
    return (
        <div className={styles.container}>
            <div className={styles.termsAndConditions}>
                <div className={styles.text}>
                    <h2>Set Up MetaMask Wallet</h2>
                    <p>
                        MetaMask is a wallet for accessing Ethereum based decentralized applications, or "DApps"
                        in your browser. Below are the steps to get started with MetaMask:
                    </p>

                    <h3>Step 1: Install MetaMask Extension</h3>
                    <p>
                        Download and install MetaMask from the <a href="https://metamask.io/" target="_blank"
                                                                  rel="noopener noreferrer">official website</a>, Chrome Web Store, Firefox Add-ons, or
                        the respective extensions store for your browser. Make sure you're visiting the official MetaMask page.
                    </p>

                    <h3>Step 2: Create a Wallet</h3>
                    <p>
                        After successful installation, click on the MetaMask icon in your browser extensions
                        to start the setup process. Choose the option "Create a Wallet". Set a strong password, and remember to safely store it.
                    </p>

                    <h3>Step 3: Backup Your Secret Recovery Phrase</h3>
                    <p>
                        MetaMask will provide you with a Secret Recovery Phrase (a set of 12 words).
                        It's important that you write this down and keep it in a safe place, as it is the only
                        way to recover your wallet if you forget your password or lose access to your device.
                        Never share it with anyone, and be wary of phishing attempts.
                    </p>

                    <h3>Step 4: Connect to the Ethereum Network</h3>
                    <p>
                        Your MetaMask wallet is now set up and ready to interact with the Ethereum network,
                        and subsequently, any decentralized applications, like our platform. To start, you'll need
                        to buy or transfer some Ethereum (ETH) to your wallet.
                    </p>

                    <h3>Step 5: Interact with DApps</h3>
                    <p>
                        Now that you've set up your wallet and have some ETH, you can interact with our games and any other DApps.
                        Click on the MetaMask extension whenever you need to approve transactions or sign messages in our games.
                    </p>

                    <h3>Security</h3>
                    <p>
                        Security is of utmost importance. Do not share your Secret Recovery Phrase with anyone,
                        keep your password secure, and always make sure you are on the correct website before
                        entering your information or approving transactions.
                    </p>

                    <h2>Setting up the Arbitrum Network</h2>
                    <p>
                        To interact with the Arbitrum network, you will need to add it as a custom RPC network in your MetaMask wallet.
                        Follow these steps:
                    </p>

                    <h3>Option 1: Manually add Arbitrum network</h3>
                    <ol>
                        <li>Open MetaMask and click on the network dropdown (usually it will show "Main Ethereum Network").</li>
                        <li>At the bottom of the dropdown, click on "Custom RPC".</li>
                        <li>In the "Network Name" field, enter: <strong>Arbitrum One</strong>.</li>
                        <li>In the "New RPC URL" field, enter: <strong>https://arb1.arbitrum.io/rpc</strong>.</li>
                        <li>In the "Chain ID" field, enter: <strong>42161</strong>.</li>
                        <li>In the "Currency Symbol" field, enter: <strong>ETH</strong>.</li>
                        <li>Click on "Save". The Arbitrum network should now be available in the network dropdown.</li>
                    </ol>

                    <h3>Option 2: Automatically add Arbitrum network through Chainlist</h3>
                    <ol>
                        <li>Visit <a href="https://chainlist.org/chain/42161" target="_blank" rel="noopener noreferrer">https://chainlist.org/chain/42161</a>.</li>
                        <li>Click on "Connect Wallet" to connect your MetaMask wallet.</li>
                        <li>Once your wallet is connected, click on "Add to MetaMask" to add the Arbitrum network.</li>
                        <li>MetaMask will open a popup to confirm the network addition. Click on "Approve" and then "Switch network".</li>
                    </ol>

                    <h2>Bridge to Arbitrum</h2>
                    <p>
                        In the context of blockchain technology, a bridge is a connection that allows the transfer of tokens and/or information between two different blockchain networks. It's a key component for Layer 2 solutions like Arbitrum that operate on top of a main blockchain network (like Ethereum) and need to communicate with it.
                    </p>
                    <p>
                        To interact with games on our platform that are operating on the Arbitrum network, you will need to move your ETH from the Ethereum mainnet to the Arbitrum network. This is done through a bridge.
                    </p>

                    <h3>How to Bridge ETH to Arbitrum</h3>
                    <ol>
                        <li>Make sure you have set up your MetaMask wallet and connected to the Arbitrum network as explained above.</li>
                        <li>Visit the <a href="https://bridge.arbitrum.io/" target="_blank" rel="noopener noreferrer">Arbitrum Bridge</a>.</li>
                        <li>Connect your MetaMask wallet to the bridge.</li>
                        <li>Select Ethereum for the "From" network and Arbitrum for the "To" network.</li>
                        <li>Enter the amount of ETH you want to transfer and click on "Transfer".</li>
                        <li>MetaMask will open a transaction confirmation popup. Confirm the transaction to initiate the bridge.</li>
                        <li>Please note that it may take some time for the transaction to process and for your ETH to appear on the Arbitrum network.</li>
                    </ol>


                    <h2>Contact Information</h2>
                    <p>
                        If you have any issues setting up your MetaMask wallet or interacting with our platform,
                        please don't hesitate to reach out to us.
                    </p>

                    <a href="mailto: dev@ethergamehub.com">Email: dev@ethergamehub.com</a>
                </div>
            </div>
        </div>
    );
}

export default SetUpWeb3;
