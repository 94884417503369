import {useEffect, useCallback} from 'react';
import {ethers} from "ethers";

/**
 * useFetchQuizRealms - A custom React Hook to fetch quiz realms from a contract,
 * and update the quiz realms state.
 *
 * @param account
 * @param quizRealms
 * @param selectedRealm
 * @param {Object} etherQuizWorldContract - The contract to fetch quiz realms from.
 * @param setEligibleEarlyExplorer
 * @param setEligibleMonthlyLeaderboardEntry
 * @param setEligibleRealmsAllTimeChampionEntry
 */
export const UseFetchNftEligibility = (account, quizRealms, selectedRealm, etherQuizWorldContract, setEligibleEarlyExplorer,
                                       setEligibleMonthlyLeaderboardEntry, setEligibleRealmsAllTimeChampionEntry) => {
    const fetchEligibility = useCallback(async () => {
        if (etherQuizWorldContract && selectedRealm === "NFT Minting Realm") {
            try {

                setEligibleEarlyExplorer(false);
                setEligibleRealmsAllTimeChampionEntry([]);
                setEligibleMonthlyLeaderboardEntry([]);

                const ee = await etherQuizWorldContract.methods.isEligibleToMintEarlyExplorerNFT().call({from: account});
                setEligibleEarlyExplorer(ee);

                quizRealms.map(async (realm) => {
                    const allTimeLeaderboard = await etherQuizWorldContract.methods
                        .getAllTimeChampionsLeaderboard(ethers.encodeBytes32String(realm)).call({from: account});

                    allTimeLeaderboard.map(async (ent, index) => {

                        if (ent[0] === account) {
                            const entry = {
                                "realm": realm,
                                "pos": index + 1
                            };

                            const at = await etherQuizWorldContract.methods
                                .isEligibleToMintAllTimeChampionNFT(ethers.encodeBytes32String(entry.realm), entry.pos).call({from: account});

                            if (at) {
                                setEligibleRealmsAllTimeChampionEntry(prevState => [...prevState, entry]);
                            }
                        }
                    })

                    const monthYearKeys = await etherQuizWorldContract.methods
                        .getPastLeaderboardKeys(ethers.encodeBytes32String(realm)).call({from: account});

                    monthYearKeys.map(async (key) => {
                        const leaderboard = await etherQuizWorldContract.methods
                            .getPastLeaderboard(ethers.encodeBytes32String(realm), key).call({from: account});

                        leaderboard.map(async (ent, index) => {
                            if (ent[0] === account) {
                                const entry = {
                                    "realm": realm,
                                    "key": key,
                                    "pos": index + 1
                                };

                                const mc = await etherQuizWorldContract.methods
                                    .isEligibleToMintMonthlyChampionNFT(ethers.encodeBytes32String(entry.realm), entry.key, entry.pos).call({from: account});

                                if (mc) {
                                    setEligibleMonthlyLeaderboardEntry(prevState => [...prevState, entry]);
                                }
                            }
                        })
                    })
                })

            } catch (error) {
                console.error('Error fetching nft eligibility:', error);
                // Here, you may want to handle the error more gracefully,
                // such as updating the UI with an error message.
            }
        }
    }, [etherQuizWorldContract, selectedRealm, account,
        setEligibleEarlyExplorer, quizRealms,
        setEligibleMonthlyLeaderboardEntry, setEligibleRealmsAllTimeChampionEntry]);

    useEffect(() => {
        fetchEligibility();
    }, [fetchEligibility]);
};
