import { useCallback } from 'react';
import { verifyQuizAnswersAndSign } from "../functions/VerifyQuizAnswersAndSign";
import {calculateTimeTaken} from "../functions/CalculateTimeTaken";
import {ethers} from "ethers";

export function useSubmitChallengeScore(
    functions, realm, account, etherQuizWorldContract, onScoreSubmitted,
    answers, startTime, timeRemaining, setTimeRemaining,dispatch) {
    return useCallback(async () => {
        dispatch({ type: 'setChallengeStarted', payload: false });
        dispatch({ type: 'setLoading', payload: true });
        dispatch({ type: 'setDisplayScore', payload: 0 });
        const timeTaken = calculateTimeTaken(startTime, timeRemaining);
        dispatch({ type: 'setTime', payload: timeTaken });

        const {
            signature,
            calculatedScore
        } = await verifyQuizAnswersAndSign(functions, realm, account, answers, timeTaken);

        try {
            if (signature) {
                const rArray = Array.from({ length: 32 }, (_, i) => signature.r[i]);
                const sArray = Array.from({ length: 32 }, (_, i) => signature.s[i]);

                const rHex = '0x' + rArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
                const sHex = '0x' + sArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

                const convertedSignature = { r: rHex, s: sHex, v: signature.v };

                await etherQuizWorldContract.methods
                    .submitChallengeScore(ethers.encodeBytes32String(realm), calculatedScore, timeTaken, convertedSignature)
                    .send({
                        from: account,
                        maxPriorityFeePerGas: '0x0',
                        maxFeePerGas: '0x5F5E100',
                        gas: '0x88A19C' });
                dispatch({ type: 'setDisplayScore', payload: calculatedScore });
                if (typeof onScoreSubmitted === 'function') {
                    onScoreSubmitted();
                }
            }
        } catch (err) {
            console.error(err);
        }

        // Replace the individual state setters with corresponding dispatch actions
        dispatch({ type: 'setCurrentQuestion', payload: 0 });
        dispatch({ type: 'setAnswers', payload: [] });
        dispatch({ type: "setLoading", payload: false });

    }, [dispatch, startTime, timeRemaining, functions, realm, account, answers, etherQuizWorldContract.methods, onScoreSubmitted]);
};
