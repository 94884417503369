// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCGxfdxLzcG-EH0kh1RowxjGBrV_1arc_U",
    authDomain: "ethergamehub.firebaseapp.com",
    projectId: "ethergamehub",
    storageBucket: "ethergamehub.appspot.com",
    messagingSenderId: "771670883688",
    appId: "1:771670883688:web:1f90047210484528a1aadd",
    measurementId: "G-Y007JD3KP4"
};

export default firebaseConfig;
